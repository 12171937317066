import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ProductsManagementFiltersContext } from "../../../../contexts/ProductsManagementFiltersProvider";
import { formatStoresToId } from "../../../../helpers";
import { useSelectedStores } from "../../../../hooks/stores";
import { getNeedToBuyProducts } from "../../../../services/product-management";
import ProductsList from "../ProductsList";

export default function Products({ curve, onlyProductsWithBuyNecessity }) {
  const { weeksInterval, category, subCategory, segment, brandsList, setBrandsList } = useContext(
    ProductsManagementFiltersContext
  );
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedStores = useSelectedStores();

  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState({});
  const [pages, setPages] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);

  const onChangePaginationHandler = (e, page) => {
    setCurrentPage(page);
  };

  const cachePage = (curve, page, orders) => {
    setCachedPages(prevState => ({
      ...prevState,
      [curve]: {
          ...(prevState[curve] || {}), // Mantém as páginas já existentes na semana ou cria um novo objeto
          [page]: orders, // Adiciona ou atualiza a página específica
      },
    }));
  };

  const getProducts = async (page = 1) => {
    setLoading(true);

    const storesId = formatStoresToId(selectedStores);

    try {
      const { data } = await getNeedToBuyProducts(
        page,
        storesId,
        weeksInterval,
        curve,
        "totalValueData",
        null,
        onlyProductsWithBuyNecessity ? 1 : 0,
        category,
        subCategory,
        segment
      );
      cachePage(curve, page, data.data);
      setProducts(data.data);
      setPages(data.pages);
    } catch (e) {
      setErrorAlert(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (cachedPages.hasOwnProperty(currentPage)) {
      setProducts(cachedPages[currentPage]);
      return;
    }

    getProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getProducts(1);
  }, [
    selectedStores,
    curve,
    weeksInterval,
    category,
    subCategory,
    segment,
    onlyProductsWithBuyNecessity
  ]);

  return (
    <div>
      {loading && (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}
      {!loading && !products?.length && (
        <div className="empty-list">
          Nenhuma necessidade de compra foi registrada neste período.
        </div>
      )}

      {!loading && !!products?.length && (
        <div className="overflow-auto">
          <ProductsList
            loading={loading}
            currentPage={currentPage}
            pages={pages}
            products={products}
            onChangePaginationHandler={onChangePaginationHandler}
          />
        </div>
      )}
    </div>
  );
}
